// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/domo/Documents/Workspace/projekt-mama/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("/home/domo/Documents/Workspace/projekt-mama/src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("/home/domo/Documents/Workspace/projekt-mama/src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("/home/domo/Documents/Workspace/projekt-mama/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-strefa-pacjenta-js": () => import("/home/domo/Documents/Workspace/projekt-mama/src/pages/strefa-pacjenta.js" /* webpackChunkName: "component---src-pages-strefa-pacjenta-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/domo/Documents/Workspace/projekt-mama/.cache/data.json")

